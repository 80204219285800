.table {
  &.table-small {
    td {
      padding: 6px !important;
      font-size: 14px !important;
    }
  }

  th {
    padding: 8px 10px !important;
    font-family: $font-family-serif !important;
    font-size: 18px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: normal !important;
    letter-spacing: normal !important;
    color: $color-black !important;
    border-width: 0 !important;
    border-bottom: 2px solid $color-dark !important;
  }

  td {
    padding: 8px !important;
    font-family: $font-family-sans !important;
    font-size: 16px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.38 !important;
    letter-spacing: normal !important;
    color: $color-black !important;
    border-width: 0 !important;
    border-top: 1px solid $color-almost-white !important;
  }

  tr:first-child {
    td {
      border-top-width: 0 !important;
    }
  }

  th,
  td {
    &:not(:first-child) {
      border-left: 1px solid $color-almost-white !important;
    }
  }

  tr,
  .k-alt {
    background-color: $color-white !important;
  }

  tbody tr:hover,
  tbody tr.k-state-hover {
    color: $color-black !important;
    background-color: rgba(43, 217, 125, 0.1) !important;
  }
}

.k-link {
  margin: 0 !important;
  padding: 0 !important;
}

.k-grid-header,
.k-widget.k-grid.table,
.k-grid-header-wrap {
  border-width: 0 !important;
}

.table{
  tr.error{
    p{
      color:$color-danger !important;
      border: 1px solid $color-danger !important;
      padding: 15px 10px !important;
    }
  }
  tbody tr.error:hover{
    background-color: $color-white !important;
  }
}


.table.table-advanced{
  th{
    .k-filtercell-operator{
      display: none;
    }

    .k-filtercell-wrapper,
    .table-filter-dropdown{
      input,
      .k-input{
        font-size: 14px !important;
        padding: 5px !important;
        min-height: unset !important;
        font-weight: normal;
      }
    }
  }

  .k-pager-numbers{
    .k-link{
      color: $color-primary;
      &:hover{
        background-color: rgba(43, 217, 125, 0.1);
      }
      &:focus{
        outline: none;
        box-shadow: none;
      }
    }
  }

  .k-link.k-state-selected{
    color:$color-white;
    background-color: $color-primary;
    &:hover{
      background-color: $color-primary;
    }
  }

  .k-link.k-pager-nav{
    &:hover{
      color: $color-primary;
      background-color: rgba(43, 217, 125, 0.1);
    }
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }

  .k-pager-sizes.k-label{
    position: relative;
    select{
       border-color:$color-dark;
      -webkit-appearance: none;
      -moz-appearance: none;
      padding: 3px 8px;
      padding-right: 17px;
      &:focus{
        outline: none;
      }
      &::-ms-expand{
        display: none;
      }
    }

    &:before{
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 6px;
      left: 4em;
      border-top: 2px solid black;
      border-right: 2px solid black;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
   }
  }
  .k-pager-info.k-label{
    color:$color-dark;
  }
  .list-counter{
    font-size: 18px;
    color: $color-black;
    font-weight: bold;
  }

  .k-header.k-sorted{
    .k-link{
      .k-icon{
        color:$color-primary;
      }
    }
  }


}

.k-animation-container.k-animation-container-relative:not(.k-calendar-container){
  min-width: 300px;

  .k-columnmenu-item-wrapper{
    min-width: 300px;
  }
  
  .k-button{
    border-color: $color-primary;
    background: $color-white;
    color: $color-primary;
  }

  .k-button.k-primary{
    background: $color-primary;
    color: $color-white;
    border-color: $color-primary;

    &:disabled{
      background: $color-dark;
      border-color: $color-dark;
    }

  }
}
