.k-textbox,
.k-input.k-textbox,
.k-textarea {
  display: block;
  width: 100% !important;
  padding: 8px !important;
  border-radius: 4px !important;
  border: solid 1px $color-dark !important;
  font-size: 16px !important;
  font-family: $font-family-sans !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: $color-black !important;
  max-width: 100% !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: unset !important;

  &:focus {
    border-color: $color-primary !important;
  }

  &:disabled {
    background-color: $color-almost-white !important;
  }

  &::selection {
    background-color: $color-light !important;
    color: $color-black !important;
  }

  &.vetis-match {
    border-color: $color-success !important;
  }

  &.vetis-mismatch {
    border-color: $color-danger !important;
  }
}

.k-dropdown,
.k-datepicker,
.k-numerictextbox {
  display: block;
  width: 100% !important;
  max-width: 100% !important;

  .k-dropdown-wrap,
  .k-picker-wrap,
  .k-numeric-wrap {
    border-radius: 4px !important;
    border: solid 1px $color-dark !important;
    background: $color-white !important;

    &.k-state-disabled {
      background: $color-almost-white !important;
    }

    .k-input {
      padding: 7px !important;
      height: unset !important;
      min-height: 36px !important;
      font-size: 16px !important;
      font-family: $font-family-sans !important;
      color: $color-black !important;
    }
  }

  .k-numeric-wrap {
    .k-select {
      background: $color-white !important;
    }
  }

  .k-dropdown-wrap {
    &:hover {
      background: $color-white !important;
    }

    .k-icon {
      &::before {
        content: " ";
        position: absolute;
        width: 10px;
        height: 10px;
        border-top: 2px solid $color-black;
        border-right: 2px solid $color-black;
        right: 9px;
        transform:rotate(135deg);
        -webkit-transform:rotate(135deg);
        -moz-transform:rotate(135deg);
        -o-transform:rotate(135deg);
        -ms-transform:rotate(135deg);
      }
    }
  }

  .k-picker-wrap {
    .k-select {
      background: $color-white;
      color: $color-black;
    }
 
    &.k-state-disabled {
      input,
      .k-select {
        background: $color-almost-white !important;
      }
    }
  }
}

.k-calendar-container{
  .k-link{
     &:hover{
      background-color: rgba(43, 217, 125, 0.1);
     }
  }

  .k-calendar-navigation{
    li{
      color: $color-dark !important;
      &:hover{
        background-color: rgba(43, 217, 125, 0.1);
       }
    }   
  }
}


.k-combobox {
  display: block;
  width: 100% !important;
  max-width: 100% !important;

  .k-dropdown-wrap {
    border-radius: 4px !important;
    border: solid 1px $color-dark !important;
    background: $color-white !important;

    .k-input {
      padding: 6.5px !important;
      height: unset !important;
      padding-right: 24px !important;
      font-size: 16px !important;
      font-family: $font-family-sans !important;
      color: $color-black !important;
    }

    &.k-state-disabled {
      background: $color-almost-white !important;
    }

    .k-icon.k-clear-value.k-i-close {
      top: 50% !important;
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
    }

    .k-icon.k-i-arrow-s {
      &::before {
        content: " ";
        position: absolute;
        width: 10px;
        height: 10px;
        border-top: 2px solid $color-black;
        border-right: 2px solid $color-black;
        right: 9px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
      }
    }
  }
}

.k-datepicker,
.k-dropdown,
.k-combobox {
  &.vetis-match {
    .k-picker-wrap,
    .k-dropdown-wrap,
    .k-combobox-wrap {
      border-color: $color-success !important;
    }
  }

  &.vetis-mismatch {
    .k-picker-wrap,
    .k-dropdown-wrap,
    .k-combobox-wrap {
      border-color: $color-danger !important;
    }
  }
}

.k-animation-container {
  z-index: 10003 !important;
  font-family: $font-family-sans !important;

  .k-list-optionlabel.k-state-selected,
  .k-item.k-state-selected {
    background: $color-primary !important;
  }

  .k-state-selected {
    .k-link {
      background: $color-primary !important;
    }
  }

  .k-today {
    color: $color-primary !important;
  }
}

.k-checkbox-label,
.k-radio-label {
  font-family: $font-family-sans;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;

  &::before {
    border-color: $color-black;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  &:hover {
    &::before {
      border-color: $color-primary;
    }
  }

  &::selection {
    color: $color-black !important;
    background: $color-light !important;
  }
}

.k-checkbox:checked + .k-checkbox-label::before,
.k-radio:checked + .k-radio-label::before {
  border-color: $color-primary;
  background-color: $color-primary;
}

.k-checkbox-label::before,
.k-checkbox-label::after,
.k-radio-label::before,
.k-radio-label::after {
  top: 0 !important;
  bottom: 0 !important;
  margin: auto;
}

.k-checkbox:disabled+.k-checkbox-label::before,
.k-radio:disabled+.k-radio-label::before{
  border-color: $color-dark !important;
}

.k-checkbox:disabled:checked +.k-checkbox-label::before,
.k-radio:disabled:checked +.k-radio-label::before{
  border-color: $color-dark !important;
  background-color: $color-dark!important;
}

.k-checkbox:disabled +.k-checkbox-label,
.k-radio:disabled +.k-radio-label{
  color : $color-dark !important;
}

.k-input {
  &::selection {
    background-color: $color-light !important;
    color: $color-black !important;
  }
}

.k-textarea:disabled {
  opacity: .6;
}