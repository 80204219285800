h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
small {
  margin: 0;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}

h1 {
  font-size: 36px;
  font-family: $font-family-serif;
  font-weight: normal;
  color: $color-secondary;
}

h2 {
  font-size: 26px;
  font-family: $font-family-sans;
  font-weight: bold;
  color: $color-primary;
}

h3 {
  font-size: 24px;
  font-family: $font-family-serif;
  font-weight: bold;
  color: $color-black;
}

h4,
h5,
h6 {
  font-size: 18px;
  font-family: $font-family-serif;
  font-weight: bold;
  color: $color-black;
}

p {
  font-size: 16px;
  font-family: $font-family-sans;
  font-weight: normal;
  line-height: 1.38;
  color: $color-black;
}

small {
  font-size: 14px;
  font-family: $font-family-sans;
  font-weight: normal;
  color: $color-black;
}

label {
  font-size: 14px;
  color: $color-black;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

label,input,div,p,span,a,h1,h2,h3,h4,h5,h6,strong,small,img,table,tr,th,td,br{
  &::selection {
      background-color: $color-light !important;
      color: $color-black !important;
  }
}

.link-color-primary{
  color: $color-primary !important;
  text-decoration: underline !important;
  &:hover{
    text-decoration: unset !important;
  }
}