.dropdown-menu {
  background-color: $color-white;
  left: -129px;
  padding: 8px 8px;
  .dropdown-item {
    font-size: $font-family-serif !important;
    display: block;
    width: 100%;
    padding: 8px 10px;
    font-weight: 400 !important;
    color: $color-black;
    text-align: left;
    text-transform: unset !important;
    margin-bottom: 8px;
    
     p{
      font-size: 14px;
     }
     &:hover{
        background-color:$color-white;
     }
  }
}

.dropdown-menu.show{
    display: block;
}




