.content-wrapper {
  position: relative;
  margin: -48px 64px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: $color-white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 148px);
}

.header-wrapper {
  padding-bottom: 64px;
  background-color: $color-white;

  .box-wrapper {
    padding: 0px 64px;

    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none;
    }

    ul.main-menu {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      border-bottom: 1px solid $color-almost-white;

      li {
        display: flex;
        padding: 8px 0px;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        text-align: center;

        &:not(:last-child) {
          border-right: 1px solid $color-almost-white;
        }

        a,
        span,
        .dropdown {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }

        .dropdown {
          .dropdown-menu {
            width: 200px;
            
            button {
              width: 100%;
            }
          }
        }

        a,
        span {
          font-family: $font-family-serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-black;
          text-transform: uppercase;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          &.active {
            color: $color-primary;
          }
        }
      }
    }

    ul.second-menu {
      display: flex;
      padding-top: 16px;
      justify-content: center;
      flex-wrap: wrap;

      li {
        display: flex;
        margin: 0px 16px;

        a {
          font-family: $font-family-sans;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: $color-black;

          &.active {
            color: $color-primary;
            font-weight: bold;
          }
        }
      }
    }
  }
}

footer {
  background-color: $color-white;
  padding: 64px;
  padding-bottom: 16px;
}

.home-donut-charts {
  .k-chart {
    height: unset !important;
    border: 1px solid $color-light;
    border-radius: 4px;
    svg{
      border-radius: 4px;
    }
  }
}

.k-chart{
  width: 100% !important;
}
