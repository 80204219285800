.pedigree .main-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.pedigree .cow-image {
  height: 70px;
}

.pedigree .main-child h4,
.pedigree .main-child h5 {
  font-size: 2rem;
  font-family: Source Sans Pro;
  font-weight: 700;
  color: #000000;
  margin: 0px;
}

.pedigree .main-child h5 {
  font-weight: 500;
}

.pedigree .grandparents{
  min-height: 284px;
}
.pedigree .grandparents .pdg-box:first-child {
  margin-bottom: 100px;
}

.pedigree .grand-grandparents {
  margin-bottom: 20px;
  min-height: 189px;
}

.pedigree .grand-grandparents:nth-child(2) {
  margin-bottom: 50px;
}

.pedigree .grand-grandparents .pdg-box:first-child {
  margin-bottom: 5px;
}

.pdg-box {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border: 1px solid;
}


.pdg-box.pdg-father {
  border-color: #ee624b;
}

.pdg-box.pdg-mother {
  border-color: #198cda;
}

.pdg-box.no-info.pdg-box.pdg-father,
.pdg-box.no-info.pdg-box.pdg-mother{
  border-color: gray;
  opacity: 0.6;
}

.pdg-box .details {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}

.pdg-box .details strong {
  font-size: 16px;
  font-family: Source Sans Pro;
  color: #000000;
}

.pdg-box .details span {
  font-size: 12px;
  font-family: Source Sans Pro;
}
.error p {
    color: #c52948 !important;
    border: 1px solid #c52948 !important;
    padding: 15px 10px !important;
    margin-bottom: 5px;
}
#root > div > div > div > div > div > div > div.k-grouping-header.k-grouping-header-flex {
    display: none;
}
body {
  background-color: #ecf0f3;
  font-family: "IBM Plex Serif", serif;
  margin: 0;
  padding: 0;
  min-width: 320px; }

.container {
  max-width: 1440px;
  width: 97%;
  padding: 0;
  margin-right: auto;
  margin-left: auto; }

hr.color-primary {
  border-color: #3743ff; }

button,
button.btn,
a.btn {
  padding: 8px;
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  border: 1px solid transparent;
  min-width: 130px; }
  button.button-icon,
  button.btn.button-icon,
  a.btn.button-icon {
    min-width: unset; }
  button:focus,
  button.btn:focus,
  a.btn:focus {
    box-shadow: none !important;
    outline: none; }
  button svg,
  button.btn svg,
  a.btn svg {
    font-size: 16px;
    margin-left: 13px; }

button.btn-primary,
a.btn-primary {
  background-color: #3743ff;
  border-color: #3743ff; }
  button.btn-primary:hover, button.btn-primary:active, button.btn-primary:focus,
  a.btn-primary:hover,
  a.btn-primary:active,
  a.btn-primary:focus {
    background-color: #3743ff !important;
    border-color: #3743ff !important; }
  button.btn-primary:disabled,
  a.btn-primary:disabled {
    border-color: #74787d !important;
    color: #ffffff !important;
    background-color: #74787d !important;
    cursor: not-allowed !important; }

button.btn-secondary,
a.btn-secondary {
  background-color: #ffffff;
  color: #3743ff;
  border-color: #3743ff; }
  button.btn-secondary:hover, button.btn-secondary:active, button.btn-secondary:focus,
  a.btn-secondary:hover,
  a.btn-secondary:active,
  a.btn-secondary:focus {
    background-color: #ffffff !important;
    color: #3743ff !important;
    border-color: #3743ff !important; }
  button.btn-secondary:disabled,
  a.btn-secondary:disabled {
    border-color: #74787d !important;
    color: #74787d !important;
    background-color: #ffffff !important;
    cursor: not-allowed !important; }

button.button-icon,
a.button-icon {
  background: transparent;
  border: none;
  color: #3743ff; }
  button.button-icon svg,
  a.button-icon svg {
    margin-left: 0px; }
  button.button-icon:hover,
  a.button-icon:hover {
    color: #3743ff; }

a.btn-secondary.active {
  background-color: #ffffff !important;
  color: #3743ff !important;
  border-color: #3743ff !important; }

a.btn-secondary.disabled {
  border-color: #74787d;
  color: #74787d;
  background-color: #ffffff;
  cursor: not-allowed;
  pointer-events: none; }

a.btn-primary.active {
  background-color: #3743ff !important;
  border-color: #3743ff !important; }

a.btn-primary.disabled {
  border-color: #74787d;
  color: #ffffff;
  background-color: #74787d;
  cursor: not-allowed;
  pointer-events: none; }

.k-textbox,
.k-input.k-textbox,
.k-textarea {
  display: block;
  width: 100% !important;
  padding: 8px !important;
  border-radius: 4px !important;
  border: solid 1px #74787d !important;
  font-size: 16px !important;
  font-family: "IBM Plex Sans", sans-serif !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  max-width: 100% !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  outline: none !important;
  box-shadow: none !important;
  height: unset !important; }
  .k-textbox:focus,
  .k-input.k-textbox:focus,
  .k-textarea:focus {
    border-color: #3743ff !important; }
  .k-textbox:disabled,
  .k-input.k-textbox:disabled,
  .k-textarea:disabled {
    background-color: #ecf0f3 !important; }
  .k-textbox::-moz-selection,
  .k-input.k-textbox::-moz-selection,
  .k-textarea::-moz-selection {
    background-color: #dce2e9 !important;
    color: #000000 !important; }
  .k-textbox::selection,
  .k-input.k-textbox::selection,
  .k-textarea::selection {
    background-color: #dce2e9 !important;
    color: #000000 !important; }
  .k-textbox.vetis-match,
  .k-input.k-textbox.vetis-match,
  .k-textarea.vetis-match {
    border-color: #2bd97d !important; }
  .k-textbox.vetis-mismatch,
  .k-input.k-textbox.vetis-mismatch,
  .k-textarea.vetis-mismatch {
    border-color: #c52948 !important; }

.k-dropdown,
.k-datepicker,
.k-numerictextbox {
  display: block;
  width: 100% !important;
  max-width: 100% !important; }
  .k-dropdown .k-dropdown-wrap,
  .k-dropdown .k-picker-wrap,
  .k-dropdown .k-numeric-wrap,
  .k-datepicker .k-dropdown-wrap,
  .k-datepicker .k-picker-wrap,
  .k-datepicker .k-numeric-wrap,
  .k-numerictextbox .k-dropdown-wrap,
  .k-numerictextbox .k-picker-wrap,
  .k-numerictextbox .k-numeric-wrap {
    border-radius: 4px !important;
    border: solid 1px #74787d !important;
    background: #ffffff !important; }
    .k-dropdown .k-dropdown-wrap.k-state-disabled,
    .k-dropdown .k-picker-wrap.k-state-disabled,
    .k-dropdown .k-numeric-wrap.k-state-disabled,
    .k-datepicker .k-dropdown-wrap.k-state-disabled,
    .k-datepicker .k-picker-wrap.k-state-disabled,
    .k-datepicker .k-numeric-wrap.k-state-disabled,
    .k-numerictextbox .k-dropdown-wrap.k-state-disabled,
    .k-numerictextbox .k-picker-wrap.k-state-disabled,
    .k-numerictextbox .k-numeric-wrap.k-state-disabled {
      background: #ecf0f3 !important; }
    .k-dropdown .k-dropdown-wrap .k-input,
    .k-dropdown .k-picker-wrap .k-input,
    .k-dropdown .k-numeric-wrap .k-input,
    .k-datepicker .k-dropdown-wrap .k-input,
    .k-datepicker .k-picker-wrap .k-input,
    .k-datepicker .k-numeric-wrap .k-input,
    .k-numerictextbox .k-dropdown-wrap .k-input,
    .k-numerictextbox .k-picker-wrap .k-input,
    .k-numerictextbox .k-numeric-wrap .k-input {
      padding: 7px !important;
      height: unset !important;
      min-height: 36px !important;
      font-size: 16px !important;
      font-family: "IBM Plex Sans", sans-serif !important;
      color: #000000 !important; }
  .k-dropdown .k-numeric-wrap .k-select,
  .k-datepicker .k-numeric-wrap .k-select,
  .k-numerictextbox .k-numeric-wrap .k-select {
    background: #ffffff !important; }
  .k-dropdown .k-dropdown-wrap:hover,
  .k-datepicker .k-dropdown-wrap:hover,
  .k-numerictextbox .k-dropdown-wrap:hover {
    background: #ffffff !important; }
  .k-dropdown .k-dropdown-wrap .k-icon::before,
  .k-datepicker .k-dropdown-wrap .k-icon::before,
  .k-numerictextbox .k-dropdown-wrap .k-icon::before {
    content: " ";
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 2px solid #000000;
    border-right: 2px solid #000000;
    right: 9px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg); }
  .k-dropdown .k-picker-wrap .k-select,
  .k-datepicker .k-picker-wrap .k-select,
  .k-numerictextbox .k-picker-wrap .k-select {
    background: #ffffff;
    color: #000000; }
  .k-dropdown .k-picker-wrap.k-state-disabled input,
  .k-dropdown .k-picker-wrap.k-state-disabled .k-select,
  .k-datepicker .k-picker-wrap.k-state-disabled input,
  .k-datepicker .k-picker-wrap.k-state-disabled .k-select,
  .k-numerictextbox .k-picker-wrap.k-state-disabled input,
  .k-numerictextbox .k-picker-wrap.k-state-disabled .k-select {
    background: #ecf0f3 !important; }

.k-calendar-container .k-link:hover {
  background-color: rgba(43, 217, 125, 0.1); }

.k-calendar-container .k-calendar-navigation li {
  color: #74787d !important; }
  .k-calendar-container .k-calendar-navigation li:hover {
    background-color: rgba(43, 217, 125, 0.1); }

.k-combobox {
  display: block;
  width: 100% !important;
  max-width: 100% !important; }
  .k-combobox .k-dropdown-wrap {
    border-radius: 4px !important;
    border: solid 1px #74787d !important;
    background: #ffffff !important; }
    .k-combobox .k-dropdown-wrap .k-input {
      padding: 6.5px !important;
      height: unset !important;
      padding-right: 24px !important;
      font-size: 16px !important;
      font-family: "IBM Plex Sans", sans-serif !important;
      color: #000000 !important; }
    .k-combobox .k-dropdown-wrap.k-state-disabled {
      background: #ecf0f3 !important; }
    .k-combobox .k-dropdown-wrap .k-icon.k-clear-value.k-i-close {
      top: 50% !important;
      -webkit-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important; }
    .k-combobox .k-dropdown-wrap .k-icon.k-i-arrow-s::before {
      content: " ";
      position: absolute;
      width: 10px;
      height: 10px;
      border-top: 2px solid #000000;
      border-right: 2px solid #000000;
      right: 9px;
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg); }

.k-datepicker.vetis-match .k-picker-wrap,
.k-datepicker.vetis-match .k-dropdown-wrap,
.k-datepicker.vetis-match .k-combobox-wrap,
.k-dropdown.vetis-match .k-picker-wrap,
.k-dropdown.vetis-match .k-dropdown-wrap,
.k-dropdown.vetis-match .k-combobox-wrap,
.k-combobox.vetis-match .k-picker-wrap,
.k-combobox.vetis-match .k-dropdown-wrap,
.k-combobox.vetis-match .k-combobox-wrap {
  border-color: #2bd97d !important; }

.k-datepicker.vetis-mismatch .k-picker-wrap,
.k-datepicker.vetis-mismatch .k-dropdown-wrap,
.k-datepicker.vetis-mismatch .k-combobox-wrap,
.k-dropdown.vetis-mismatch .k-picker-wrap,
.k-dropdown.vetis-mismatch .k-dropdown-wrap,
.k-dropdown.vetis-mismatch .k-combobox-wrap,
.k-combobox.vetis-mismatch .k-picker-wrap,
.k-combobox.vetis-mismatch .k-dropdown-wrap,
.k-combobox.vetis-mismatch .k-combobox-wrap {
  border-color: #c52948 !important; }

.k-animation-container {
  z-index: 10003 !important;
  font-family: "IBM Plex Sans", sans-serif !important; }
  .k-animation-container .k-list-optionlabel.k-state-selected,
  .k-animation-container .k-item.k-state-selected {
    background: #3743ff !important; }
  .k-animation-container .k-state-selected .k-link {
    background: #3743ff !important; }
  .k-animation-container .k-today {
    color: #3743ff !important; }

.k-checkbox-label,
.k-radio-label {
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px; }
  .k-checkbox-label::before,
  .k-radio-label::before {
    border-color: #000000;
    box-shadow: none !important; }
  .k-checkbox-label:hover::before,
  .k-radio-label:hover::before {
    border-color: #3743ff; }
  .k-checkbox-label::-moz-selection,
  .k-radio-label::-moz-selection {
    color: #000000 !important;
    background: #dce2e9 !important; }
  .k-checkbox-label::selection,
  .k-radio-label::selection {
    color: #000000 !important;
    background: #dce2e9 !important; }

.k-checkbox:checked + .k-checkbox-label::before,
.k-radio:checked + .k-radio-label::before {
  border-color: #3743ff;
  background-color: #3743ff; }

.k-checkbox-label::before,
.k-checkbox-label::after,
.k-radio-label::before,
.k-radio-label::after {
  top: 0 !important;
  bottom: 0 !important;
  margin: auto; }

.k-checkbox:disabled + .k-checkbox-label::before,
.k-radio:disabled + .k-radio-label::before {
  border-color: #74787d !important; }

.k-checkbox:disabled:checked + .k-checkbox-label::before,
.k-radio:disabled:checked + .k-radio-label::before {
  border-color: #74787d !important;
  background-color: #74787d !important; }

.k-checkbox:disabled + .k-checkbox-label,
.k-radio:disabled + .k-radio-label {
  color: #74787d !important; }

.k-input::-moz-selection {
  background-color: #dce2e9 !important;
  color: #000000 !important; }

.k-input::selection {
  background-color: #dce2e9 !important;
  color: #000000 !important; }

.k-textarea:disabled {
  opacity: .6; }

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
small {
  margin: 0;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal; }

h1 {
  font-size: 36px;
  font-family: "IBM Plex Serif", serif;
  font-weight: normal;
  color: #2bd97d; }

h2 {
  font-size: 26px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: bold;
  color: #3743ff; }

h3 {
  font-size: 24px;
  font-family: "IBM Plex Serif", serif;
  font-weight: bold;
  color: #000000; }

h4,
h5,
h6 {
  font-size: 18px;
  font-family: "IBM Plex Serif", serif;
  font-weight: bold;
  color: #000000; }

p {
  font-size: 16px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: normal;
  line-height: 1.38;
  color: #000000; }

small {
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: normal;
  color: #000000; }

label {
  font-size: 14px;
  color: #000000; }

a {
  color: inherit; }
  a:hover {
    color: inherit; }

label::-moz-selection, input::-moz-selection, div::-moz-selection, p::-moz-selection, span::-moz-selection, a::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, h6::-moz-selection, strong::-moz-selection, small::-moz-selection, img::-moz-selection, table::-moz-selection, tr::-moz-selection, th::-moz-selection, td::-moz-selection, br::-moz-selection {
  background-color: #dce2e9 !important;
  color: #000000 !important; }

label::selection, input::selection, div::selection, p::selection, span::selection, a::selection, h1::selection, h2::selection, h3::selection, h4::selection, h5::selection, h6::selection, strong::selection, small::selection, img::selection, table::selection, tr::selection, th::selection, td::selection, br::selection {
  background-color: #dce2e9 !important;
  color: #000000 !important; }

.link-color-primary {
  color: #3743ff !important;
  text-decoration: underline !important; }
  .link-color-primary:hover {
    text-decoration: unset !important; }

.tabs {
  margin-bottom: 32px; }
  .tabs .nav {
    position: relative; }
    .tabs .nav::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: #3743ff;
      left: 0px;
      bottom: 0px;
      z-index: 1; }
  .tabs .nav-item .nav-link {
    position: relative;
    font-size: 16px;
    font-family: "IBM Plex Sans", sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 0px;
    color: #74787d; }
    .tabs .nav-item .nav-link:hover {
      text-decoration: underline; }
    .tabs .nav-item .nav-link.active {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border-color: #3743ff;
      border-bottom: 0px;
      color: #3743ff;
      background: #ffffff; }
      .tabs .nav-item .nav-link.active::before {
        content: '';
        width: 100%;
        background: #ffffff;
        left: 0px;
        position: absolute;
        height: 4px;
        left: 0px;
        bottom: -2px;
        z-index: 2; }
      .tabs .nav-item .nav-link.active:hover {
        text-decoration: none; }

.steps .nav {
  margin-bottom: 20px;
  border-bottom: 1px solid #3743ff; }

.steps .nav-item {
  margin-bottom: 20px;
  display: inline-flex;
  flex: unset; }
  .steps .nav-item .nav-link {
    position: relative;
    font-size: 16px;
    font-family: "IBM Plex Sans", sans-serif;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    line-height: 1.38;
    border-radius: 0px;
    color: #000000;
    background: #ffffff;
    text-align: left;
    padding: 8px 38px;
    padding-left: 0; }
    .steps .nav-item .nav-link::before {
      content: '';
      position: absolute;
      width: 9px;
      height: 9px;
      border: 2px solid #000000;
      border-left: 0;
      border-bottom: 0;
      right: 16px;
      top: 50%;
      -webkit-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg); }
    .steps .nav-item .nav-link:hover .title {
      text-decoration: underline; }
    .steps .nav-item .nav-link.active {
      color: #3743ff;
      background: #ffffff; }
      .steps .nav-item .nav-link.active .step {
        background: #3743ff;
        color: #ffffff; }
    .steps .nav-item .nav-link .step {
      font-family: "IBM Plex Serif", serif;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
      padding: 12px 18px;
      border-radius: 50%;
      background: #dce2e9;
      margin-right: 8px; }

.steps .nav-item:last-child .nav-link::before {
  display: none; }

.steps-action-btns {
  border-top: 1px solid #3743ff;
  padding-top: 20px; }

.k-dialog-wrapper {
  font-family: "IBM Plex Serif", serif !important; }
  .k-dialog-wrapper .k-widget,
  .k-dialog-wrapper .k-window,
  .k-dialog-wrapper .k-dialog {
    border-radius: 4px; }
    .k-dialog-wrapper .k-widget .k-dialog-titlebar,
    .k-dialog-wrapper .k-window .k-dialog-titlebar,
    .k-dialog-wrapper .k-dialog .k-dialog-titlebar {
      background-color: #ffffff;
      color: #000000;
      font-size: 18px;
      font-family: "IBM Plex Sans", sans-serif;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 1px solid #3743ff; }
      .k-dialog-wrapper .k-widget .k-dialog-titlebar .k-window-actions.k-dialog-actions .k-dialog-close:hover::before,
      .k-dialog-wrapper .k-window .k-dialog-titlebar .k-window-actions.k-dialog-actions .k-dialog-close:hover::before,
      .k-dialog-wrapper .k-dialog .k-dialog-titlebar .k-window-actions.k-dialog-actions .k-dialog-close:hover::before {
        opacity: 0; }
    .k-dialog-wrapper .k-widget .k-dialog-buttongroup,
    .k-dialog-wrapper .k-window .k-dialog-buttongroup,
    .k-dialog-wrapper .k-dialog .k-dialog-buttongroup {
      border-color: #3743ff !important;
      padding: 20px 83px; }
    .k-dialog-wrapper .k-widget::-moz-selection,
    .k-dialog-wrapper .k-window::-moz-selection,
    .k-dialog-wrapper .k-dialog::-moz-selection {
      background-color: #dce2e9 !important;
      color: #000000 !important; }
    .k-dialog-wrapper .k-widget::selection,
    .k-dialog-wrapper .k-window::selection,
    .k-dialog-wrapper .k-dialog::selection {
      background-color: #dce2e9 !important;
      color: #000000 !important; }

.k-dialog-wrapper.delete .k-dialog-buttongroup {
  border-color: #ffffff !important; }

.content-wrapper {
  position: relative;
  margin: -48px 64px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 148px); }

.header-wrapper {
  padding-bottom: 64px;
  background-color: #ffffff; }
  .header-wrapper .box-wrapper {
    padding: 0px 64px; }
    .header-wrapper .box-wrapper ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style: none; }
    .header-wrapper .box-wrapper ul.main-menu {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      border-bottom: 1px solid #ecf0f3; }
      .header-wrapper .box-wrapper ul.main-menu li {
        display: flex;
        padding: 8px 0px;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        text-align: center; }
        .header-wrapper .box-wrapper ul.main-menu li:not(:last-child) {
          border-right: 1px solid #ecf0f3; }
        .header-wrapper .box-wrapper ul.main-menu li a,
        .header-wrapper .box-wrapper ul.main-menu li span,
        .header-wrapper .box-wrapper ul.main-menu li .dropdown {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%; }
        .header-wrapper .box-wrapper ul.main-menu li .dropdown .dropdown-menu {
          width: 200px; }
          .header-wrapper .box-wrapper ul.main-menu li .dropdown .dropdown-menu button {
            width: 100%; }
        .header-wrapper .box-wrapper ul.main-menu li a,
        .header-wrapper .box-wrapper ul.main-menu li span {
          font-family: "IBM Plex Serif", serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000000;
          text-transform: uppercase;
          cursor: pointer; }
          .header-wrapper .box-wrapper ul.main-menu li a:hover,
          .header-wrapper .box-wrapper ul.main-menu li span:hover {
            text-decoration: underline; }
          .header-wrapper .box-wrapper ul.main-menu li a.active,
          .header-wrapper .box-wrapper ul.main-menu li span.active {
            color: #3743ff; }
    .header-wrapper .box-wrapper ul.second-menu {
      display: flex;
      padding-top: 16px;
      justify-content: center;
      flex-wrap: wrap; }
      .header-wrapper .box-wrapper ul.second-menu li {
        display: flex;
        margin: 0px 16px; }
        .header-wrapper .box-wrapper ul.second-menu li a {
          font-family: "IBM Plex Sans", sans-serif;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: #000000; }
          .header-wrapper .box-wrapper ul.second-menu li a.active {
            color: #3743ff;
            font-weight: bold; }

footer {
  background-color: #ffffff;
  padding: 64px;
  padding-bottom: 16px; }

.home-donut-charts .k-chart {
  height: unset !important;
  border: 1px solid #dce2e9;
  border-radius: 4px; }
  .home-donut-charts .k-chart svg {
    border-radius: 4px; }

.k-chart {
  width: 100% !important; }

.dropdown-menu {
  background-color: #ffffff;
  left: -129px;
  padding: 8px 8px; }
  .dropdown-menu .dropdown-item {
    font-size: "IBM Plex Serif", serif !important;
    display: block;
    width: 100%;
    padding: 8px 10px;
    font-weight: 400 !important;
    color: #000000;
    text-align: left;
    text-transform: unset !important;
    margin-bottom: 8px; }
    .dropdown-menu .dropdown-item p {
      font-size: 14px; }
    .dropdown-menu .dropdown-item:hover {
      background-color: #ffffff; }

.dropdown-menu.show {
  display: block; }

.table.table-small td {
  padding: 6px !important;
  font-size: 14px !important; }

.table th {
  padding: 8px 10px !important;
  font-family: "IBM Plex Serif", serif !important;
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  border-width: 0 !important;
  border-bottom: 2px solid #74787d !important; }

.table td {
  padding: 8px !important;
  font-family: "IBM Plex Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.38 !important;
  letter-spacing: normal !important;
  color: #000000 !important;
  border-width: 0 !important;
  border-top: 1px solid #ecf0f3 !important; }

.table tr:first-child td {
  border-top-width: 0 !important; }

.table th:not(:first-child),
.table td:not(:first-child) {
  border-left: 1px solid #ecf0f3 !important; }

.table tr,
.table .k-alt {
  background-color: #ffffff !important; }

.table tbody tr:hover,
.table tbody tr.k-state-hover {
  color: #000000 !important;
  background-color: rgba(43, 217, 125, 0.1) !important; }

.k-link {
  margin: 0 !important;
  padding: 0 !important; }

.k-grid-header,
.k-widget.k-grid.table,
.k-grid-header-wrap {
  border-width: 0 !important; }

.table tr.error p {
  color: #c52948 !important;
  border: 1px solid #c52948 !important;
  padding: 15px 10px !important; }

.table tbody tr.error:hover {
  background-color: #ffffff !important; }

.table.table-advanced th .k-filtercell-operator {
  display: none; }

.table.table-advanced th .k-filtercell-wrapper input,
.table.table-advanced th .k-filtercell-wrapper .k-input,
.table.table-advanced th .table-filter-dropdown input,
.table.table-advanced th .table-filter-dropdown .k-input {
  font-size: 14px !important;
  padding: 5px !important;
  min-height: unset !important;
  font-weight: normal; }

.table.table-advanced .k-pager-numbers .k-link {
  color: #3743ff; }
  .table.table-advanced .k-pager-numbers .k-link:hover {
    background-color: rgba(43, 217, 125, 0.1); }
  .table.table-advanced .k-pager-numbers .k-link:focus {
    outline: none;
    box-shadow: none; }

.table.table-advanced .k-link.k-state-selected {
  color: #ffffff;
  background-color: #3743ff; }
  .table.table-advanced .k-link.k-state-selected:hover {
    background-color: #3743ff; }

.table.table-advanced .k-link.k-pager-nav:hover {
  color: #3743ff;
  background-color: rgba(43, 217, 125, 0.1); }

.table.table-advanced .k-link.k-pager-nav:focus {
  outline: none;
  box-shadow: none; }

.table.table-advanced .k-pager-sizes.k-label {
  position: relative; }
  .table.table-advanced .k-pager-sizes.k-label select {
    border-color: #74787d;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 3px 8px;
    padding-right: 17px; }
    .table.table-advanced .k-pager-sizes.k-label select:focus {
      outline: none; }
    .table.table-advanced .k-pager-sizes.k-label select::-ms-expand {
      display: none; }
  .table.table-advanced .k-pager-sizes.k-label:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 6px;
    left: 4em;
    border-top: 2px solid black;
    border-right: 2px solid black;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    -ms-transform: rotate(135deg); }

.table.table-advanced .k-pager-info.k-label {
  color: #74787d; }

.table.table-advanced .list-counter {
  font-size: 18px;
  color: #000000;
  font-weight: bold; }

.table.table-advanced .k-header.k-sorted .k-link .k-icon {
  color: #3743ff; }

.k-animation-container.k-animation-container-relative:not(.k-calendar-container) {
  min-width: 300px; }
  .k-animation-container.k-animation-container-relative:not(.k-calendar-container) .k-columnmenu-item-wrapper {
    min-width: 300px; }
  .k-animation-container.k-animation-container-relative:not(.k-calendar-container) .k-button {
    border-color: #3743ff;
    background: #ffffff;
    color: #3743ff; }
  .k-animation-container.k-animation-container-relative:not(.k-calendar-container) .k-button.k-primary {
    background: #3743ff;
    color: #ffffff;
    border-color: #3743ff; }
    .k-animation-container.k-animation-container-relative:not(.k-calendar-container) .k-button.k-primary:disabled {
      background: #74787d;
      border-color: #74787d; }

.alert.alert-success {
  color: #ffffff;
  background-color: #2bd97d;
  border-color: #2bd97d; }

.list-box .elements-list,
.list-box .elements-group {
  height: 400px;
  overflow: scroll;
  border: 1px solid #74787d; }

.list-box .item {
  padding: 4px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .list-box .item:hover {
    background-color: rgba(43, 217, 125, 0.1); }
  .list-box .item:focus {
    outline: none; }

.list-box .item.selected {
  background: #3743ff;
  color: #ffffff; }
  .list-box .item.selected:hover {
    background-color: #3743ff; }

.list-box .actions {
  margin: auto; }

.k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell.k-state-disabled {
  opacity: 1 !important; }

.k-spreadsheet .k-spreadsheet-pane .k-spreadsheet-cell.k-dirty-cell {
  background: #c52948 !important;
  color: white !important; }

.k-spreadsheet .k-spreadsheet-toolbar .btn.btn-primary {
  background-image: unset !important;
  display: flex; }

.k-spreadsheet .k-spreadsheet-cell {
  font-family: "IBM Plex Sans", sans-serif !important; }

.k-spreadsheet .k-spreadsheet-cell:not(.k-state-disabled) {
  font-size: 14px !important; }

.k-spreadsheet .k-tabstrip-items .k-item {
  opacity: 0 !important; }

.k-spreadsheet .k-spreadsheet-action-bar {
  padding: 7px 15px !important; }

.k-spreadsheet #btnSave,
.k-spreadsheet #btnDownload {
  padding: 8px  !important;
  font-size: 16px  !important;
  font-family: "IBM Plex Sans", sans-serif !important;
  font-weight: 400  !important;
  font-stretch: normal  !important;
  font-style: normal  !important;
  line-height: normal  !important;
  letter-spacing: normal  !important;
  text-align: center  !important;
  color: #ffffff !important;
  border-radius: 4px  !important;
  border: 1px solid transparent  !important;
  min-width: 130px  !important;
  background-image: unset  !important; }
  .k-spreadsheet #btnSave:focus,
  .k-spreadsheet #btnDownload:focus {
    box-shadow: none !important;
    outline: none  !important; }

.k-spreadsheet #btnSave {
  background-color: #3743ff !important;
  border-color: #3743ff !important; }
  .k-spreadsheet #btnSave:hover, .k-spreadsheet #btnSave:active, .k-spreadsheet #btnSave:focus {
    background-color: #3743ff !important;
    border-color: #3743ff !important; }
  .k-spreadsheet #btnSave:disabled {
    border-color: #74787d !important;
    color: #ffffff !important;
    background-color: #74787d !important;
    cursor: not-allowed !important; }

.k-spreadsheet #btnDownload {
  background-color: #ffffff !important;
  color: #3743ff !important;
  border-color: #3743ff !important; }
  .k-spreadsheet #btnDownload:hover, .k-spreadsheet #btnDownload:active, .k-spreadsheet #btnDownload:focus {
    background-color: #ffffff !important;
    color: #3743ff !important;
    border-color: #3743ff !important; }
  .k-spreadsheet #btnDownload:disabled {
    border-color: #74787d !important;
    color: #74787d !important;
    background-color: #ffffff !important;
    cursor: not-allowed !important; }

.k-spreadsheet .k-spreadsheet-sheets-bar-add {
  opacity: 0 !important; }

.k-list {
  max-height: 180px;
  overflow: scroll; }

.spreadsheet-duplicate-animals-container .spreadsheet-duplicate-count {
  color: #c52948 !important; }

.spreadsheet-duplicate-animals-container .spreadsheet-duplicate-animal {
  border-bottom: 1px solid black;
  margin-bottom: 16px; }
  .spreadsheet-duplicate-animals-container .spreadsheet-duplicate-animal:last-of-type {
    border-bottom: unset;
    margin-bottom: unset; }

.spreadsheet-warning-msg {
  background: #ff9400;
  padding: 8px;
  border-radius: 4px;
  color: #ffffff; }

