.tabs {
  margin-bottom: 32px;

  .nav {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $color-primary;
      left: 0px;
      bottom: 0px;
      z-index: 1;
    }
  }

  .nav-item {
    .nav-link {
      position: relative;
      font-size: 16px;
      font-family: $font-family-sans;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      border: 1px solid $color-white;
      border-radius: 0px;
      color: $color-dark;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-color: $color-primary;
        border-bottom: 0px;
        color: $color-primary;
        background: $color-white;

        &::before {
          content: '';
          width: 100%;
          background: $color-white;
          left: 0px;
          position: absolute;
          height: 4px;
          left: 0px;
          bottom: -2px;
          z-index: 2;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.steps {
  .nav {
    margin-bottom: 20px;
    border-bottom: 1px solid $color-primary;
  }

  .nav-item {
    margin-bottom: 20px;
    display: inline-flex;
    flex: unset;

    .nav-link {
      position: relative;
      font-size: 16px;
      font-family: $font-family-sans;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      line-height: 1.38;
      border-radius: 0px;
      color: $color-black;
      background: $color-white;
      text-align: left;
      padding: 8px 38px;
      padding-left: 0;

      &::before {
        content: '';
        position: absolute;
        width: 9px;
        height: 9px;
        border: 2px solid $color-black;
        border-left: 0;
        border-bottom: 0;
        right: 16px;
        top: 50%;
        -webkit-transform: translateY(-50%) rotate(45deg);
        -ms-transform: translateY(-50%) rotate(45deg);
        transform: translateY(-50%) rotate(45deg);
      }

      &:hover {
        .title {
          text-decoration: underline;
        }
      }

      &.active {
        color: $color-primary;
        background: $color-white;

        .step {
          background: $color-primary;
          color: $color-white;
        }
      }

      .step {
        font-family: $font-family-serif;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-black;
        padding: 12px 18px;
        border-radius: 50%;
        background: $color-light;
        margin-right: 8px;
      }
    }
  }

  .nav-item:last-child {
    .nav-link {
      &::before {
        display: none;
      }
    }
  }
}

.steps-action-btns {
  border-top: 1px solid $color-primary;
  padding-top: 20px;
}