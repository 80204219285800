.list-box {
  .elements-list,
  .elements-group {
    height: 400px;
    overflow: scroll;
    border: 1px solid $color-dark;
  }

  .item{
      padding: 4px;
      cursor: pointer;
      -webkit-user-select: none;  
      -moz-user-select: none;    
      -ms-user-select: none;      
      user-select: none;
      &:hover{
        background-color: rgba(43, 217, 125, 0.1);
      }
      &:focus{
        outline: none;
      }
  }

  .item.selected{
      background:  $color-primary;
      color:$color-white;

      &:hover{
        background-color: $color-primary;
      }
  }

  .actions{
    margin: auto;
  }
}