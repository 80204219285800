// Fonts
$font-family-sans: 'IBM Plex Sans', sans-serif;
$font-family-serif: 'IBM Plex Serif', serif;

// Colors
$color-primary: #3743ff;
$color-secondary: #2bd97d;
$color-black: #000000;
$color-dark: #74787d;
$color-light: #dce2e9;
$color-almost-white: #ecf0f3;
$color-white: #ffffff;
$color-success: #2bd97d;
$color-warning: #ff9400;
$color-danger: #c52948;