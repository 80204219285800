body {
  background-color: $color-almost-white;
  font-family: $font-family-serif;
  margin: 0;
  padding: 0;
  min-width: 320px;
}

.container {
  max-width: 1440px;
  width: 97%;
  padding: 0;
  margin-right: auto;
  margin-left: auto;
}

hr.color-primary{
  border-color: $color-primary;
}