button,
button.btn,
a.btn {
  padding: 8px;
  font-size: 16px;
  font-family: $font-family-sans;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $color-white;
  border-radius: 4px;
  border: 1px solid transparent;
  min-width: 130px;

  &.button-icon {
    min-width: unset;
  }

  &:focus {
    box-shadow: none !important;
    outline: none;
  }

  svg {
    font-size: 16px;
    margin-left: 13px;
  }
}

button.btn-primary,
a.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
  }

  &:disabled {
    border-color: $color-dark !important;
    color: $color-white !important;
    background-color: $color-dark !important;
    cursor: not-allowed !important;
  }
}

button.btn-secondary,
a.btn-secondary {
  background-color: $color-white;
  color: $color-primary;
  border-color: $color-primary;

  &:hover,
  &:active,
  &:focus{
    background-color: $color-white !important;
    color: $color-primary !important;
    border-color: $color-primary !important;
  }

  &:disabled {
    border-color: $color-dark !important;
    color: $color-dark !important;
    background-color: $color-white !important;
    cursor: not-allowed !important;
  }
}

button.button-icon,
a.button-icon {
  background: transparent;
  border: none;
  color: $color-primary;

  svg {
    margin-left: 0px;
  }

  &:hover{
    color: $color-primary;
  }
}

a.btn-secondary {
  &.active {
    background-color: $color-white !important;
    color: $color-primary !important;
    border-color: $color-primary !important;
  }

  &.disabled {
    border-color: $color-dark;
    color: $color-dark;
    background-color: $color-white;
    cursor: not-allowed;
    pointer-events: none;
  }
}

a.btn-primary {
  &.active {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
  }

  &.disabled{
    border-color: $color-dark;
    color: $color-white;
    background-color: $color-dark;
    cursor: not-allowed;
    pointer-events: none;
  }
}


