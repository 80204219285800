.pedigree .main-child {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.pedigree .cow-image {
  height: 70px;
}

.pedigree .main-child h4,
.pedigree .main-child h5 {
  font-size: 2rem;
  font-family: Source Sans Pro;
  font-weight: 700;
  color: #000000;
  margin: 0px;
}

.pedigree .main-child h5 {
  font-weight: 500;
}

.pedigree .grandparents{
  min-height: 284px;
}
.pedigree .grandparents .pdg-box:first-child {
  margin-bottom: 100px;
}

.pedigree .grand-grandparents {
  margin-bottom: 20px;
  min-height: 189px;
}

.pedigree .grand-grandparents:nth-child(2) {
  margin-bottom: 50px;
}

.pedigree .grand-grandparents .pdg-box:first-child {
  margin-bottom: 5px;
}

.pdg-box {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  border: 1px solid;
}


.pdg-box.pdg-father {
  border-color: #ee624b;
}

.pdg-box.pdg-mother {
  border-color: #198cda;
}

.pdg-box.no-info.pdg-box.pdg-father,
.pdg-box.no-info.pdg-box.pdg-mother{
  border-color: gray;
  opacity: 0.6;
}

.pdg-box .details {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
}

.pdg-box .details strong {
  font-size: 16px;
  font-family: Source Sans Pro;
  color: #000000;
}

.pdg-box .details span {
  font-size: 12px;
  font-family: Source Sans Pro;
}