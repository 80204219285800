.k-spreadsheet {
  .k-spreadsheet-pane {
    .k-spreadsheet-cell.k-state-disabled {
      opacity: 1 !important;
    }

    .k-spreadsheet-cell.k-dirty-cell {
      background: $color-danger !important;
      color: white !important;
    }
  }

  .k-spreadsheet-toolbar {
    .btn.btn-primary {
      background-image: unset !important;
      display: flex;
    }
  }

  .k-spreadsheet-cell{
    font-family: $font-family-sans !important;
  }
  .k-spreadsheet-cell:not(.k-state-disabled){
    font-size: 14px !important;
  }

  .k-tabstrip-items{
      .k-item{
        opacity: 0 !important;
      }
  }

  .k-spreadsheet-action-bar{
    padding: 7px 15px !important;
  }

  #btnSave,
  #btnDownload{
    padding: 8px  !important;
    font-size: 16px  !important;
    font-family: $font-family-sans  !important;
    font-weight: 400  !important;
    font-stretch: normal  !important;
    font-style: normal  !important;
    line-height: normal  !important;
    letter-spacing: normal  !important;
    text-align: center  !important;
    color: $color-white  !important;
    border-radius: 4px  !important;
    border: 1px solid transparent  !important;
    min-width: 130px  !important;
    background-image: unset  !important;
  
  
    &:focus {
      box-shadow: none !important;
      outline: none  !important;
    }
  }

  #btnSave{
    background-color: $color-primary  !important;
    border-color: $color-primary  !important;
  
    &:hover,
    &:active,
    &:focus {
      background-color: $color-primary !important;
      border-color: $color-primary !important;
    }
  
    &:disabled {
      border-color: $color-dark !important;
      color: $color-white !important;
      background-color: $color-dark !important;
      cursor: not-allowed !important;
    }
  }

  #btnDownload{
    background-color: $color-white !important;
    color: $color-primary !important;
    border-color: $color-primary !important;
  
    &:hover,
    &:active,
    &:focus{
      background-color: $color-white !important;
      color: $color-primary !important;
      border-color: $color-primary !important;
    }
  
    &:disabled {
      border-color: $color-dark !important;
      color: $color-dark !important;
      background-color: $color-white !important;
      cursor: not-allowed !important;
    }
  }

  .k-spreadsheet-sheets-bar-add{
    opacity: 0 !important;
  }
}

.k-list {
  max-height: 180px;
  overflow: scroll;
}

.spreadsheet-duplicate-animals-container {
  .spreadsheet-duplicate-count {
    color: $color-danger !important;
  }

  .spreadsheet-duplicate-animal {
    border-bottom: 1px solid black;
    margin-bottom: 16px;

    &:last-of-type{
        border-bottom: unset;
        margin-bottom: unset;
    }
  }
}

.spreadsheet-warning-msg{
  background: $color-warning;
  padding: 8px;
  border-radius: 4px;
  color: $color-white,
}