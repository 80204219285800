.k-dialog-wrapper{
    font-family: $font-family-serif !important;
    .k-widget,
    .k-window, 
    .k-dialog{
        border-radius: 4px;
        .k-dialog-titlebar{
            background-color: $color-white;
            color: $color-black;
            font-size: 18px;
            font-family: $font-family-sans;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border-bottom: 1px solid $color-primary;

            .k-window-actions.k-dialog-actions .k-dialog-close{
                &:hover{
                    &::before{
                        opacity: 0;
                    }
                }
            }
        }

        .k-dialog-buttongroup{
            border-color: $color-primary !important;
            padding:20px 83px ;
        }

      

        &::selection {
            background-color: $color-light !important;
            color: $color-black !important;
        }
    } 
} 


.k-dialog-wrapper.delete{
    .k-dialog-buttongroup{
        border-color: $color-white !important;
    }
}